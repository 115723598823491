.about-blocks-block-container {
    margin-top: 40px;
    margin-bottom: 40px;
}

.ioacf-block {
    position: relative;

    .ioacf-block-details {
        position: absolute;
        top: 13%;
        padding: 10px 25px;
        background: #fff;
    }

    .ioacf-block-title {
        a {
            font-size: 36px;
            line-height: 36px;
            font-weight: 700;
            color: #333333;
        }
    }

    .ioacf-block-button {
        a {
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
            font-family: Open Sans;
            text-transform: uppercase;
            color: #333333;
        }
    }
}

.pos-align-vbottom {
    .ioacf-block-details {
        top: 65%;
    }
}

.pos-align-vtop {
    .ioacf-block-details {
        top: 13%;
    }
}

.pos-align-vmiddle {
    .ioacf-block-details {
        top: 40%;
    }
}

.slideshow-main {
    margin-bottom: 50px;
}

.main-slider {
    position: relative;

    .flexslider {
        background: transparent;
        box-shadow: none;
    }

    .main-slide-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .product-items.slic-slider {
        .slick-track {
            margin-left: 0;
        }
    }

    .magestore-slick-slider {
        padding-left: 0;

        img {
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 auto;
        }

        button {

            &.slick-prev,
            &.slick-next {
                width: 40px;
                height: 40px;
                background: none !important;

                &:before {
                    position: absolute;
                    font-family: 'icomoon';
                    left: 8px;
                    top: 50%;
                    margin-top: -32.5px;
                    width: 30px;
                    height: 30px;
                    font-size: 65px;
                    color: $brand-primary;
                    opacity: 0.75;
                }

                &:hover {
                    background: none;

                    &:before {
                        opacity: 1;
                    }
                }
            }

            &.slick-prev {
                left: -105px;
                transform: rotate(0deg);
                top: 207px;
                &:before {
                    content: "\e96f";
                    top: 50px;
                }
            }

            &.slick-next {
                right: -84px;

                &:before {
                    content: "\e970";
                }
            }

            @include media-query(1330px) {
                &.slick-prev {
                    left: -60px;
                }

                &.slick-next {
                    right: -60px;
                }
            }

            @include media-query(1300px) {

                &.slick-prev,
                &.slick-next {
                    &:before {
                        font-size: 45px;
                        margin-top: -22.5px;
                    }
                }

                &.slick-prev {
                    left: -42px;
                }

                &.slick-next {
                    right: -38px;
                }
            }

            @include media-query(1230px) {
                &.slick-arrow {
                    display: none !important;
                }
            }
        }

        .slick-dots {
            bottom: 20px;
            width: 300px;
            left: 50%;
            margin-left: -150px;

            li {
                button {
                    width: 10px;
                    height: 10px;
                    background: $pt-gray-04;
                    border: 2px solid white;
                    border-radius: 100%;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        background: $brand-primary;
                    }
                }
            }
        }

        @include media-query(658px) {
            display: none !important;
        }
    }

    .magestore-slick-slider .slick-slide {
        display: none;
    }

    .magestore-slick-slider.slick-initialized .slick-slide {
        display: block;
    }

    .main-prodct-search {
        .search-block {
            border-radius: 10px;
            background: white;
            max-width: 320px;
            width: 100%;
            height: auto;
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translateY(-50%);
            margin-left: -530px;
            z-index: 10;
            display: none;
            min-height: 422px;
            @media only screen and (max-width:767px) {
                min-height: unset;
                top: 10px!important;
            }
            @media screen and (min-width: 768px) and (max-width: 930px) {
                min-height: unset;
                top: 43%;
            }
            @media screen and (min-width: 931px) and (max-width: 953px) {
                top: 43%;
            }
            .ui-tabs-nav {
                width: 100%;
                margin: 0;
                padding: 0;
                display: block;
                border-radius: 12px;
                list-style: none;

                li {
                    width: 50%;
                    float: left;
                    display: inline-block;
                    text-align: center;
                    cursor: pointer;

                    &[data-role='title'] {
                        background: $pt-gray-04;

                        &[aria-selected='true'] {
                            background: $brand-secondary;

                            a {
                                color: #fff;
                                &:hover {
                                    background: none;
                                }
                            }
                        }

                        a {
                            font-family: 'effra';
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                            color: $pt-black;
                            display: flex;
                            text-decoration: none;
                            width: 100%;
                            height: auto;
                            box-shadow: none;
                            outline: 0;
                            padding: 10px 0;
                            box-sizing: border-box;
                            height: 52px;
                            flex-flow: column;
                            justify-content: center;
                            align-items: center;
                            span {
                                display: inherit;
                            }

                            &:hover {
                                box-shadow: none;
                                outline: 0;
                                text-decoration: none;
                                background: $pt-gray-06;
                            }

                            &:active {
                                box-shadow: none;
                                outline: 0;
                                text-decoration: none;
                            }

                            &:focus {
                                box-shadow: none;
                                outline: 0;
                                text-decoration: none;
                            }

                            @include media-query(930px) {
                                font-size: 13px;
                                line-height: 13px;
                                padding: 8px 0;
                            }

                            @include media-query(698px, 659px) {
                                padding: 5px 0;
                            }

                            @include media-query(658px) {
                                padding: 15px 0;
                                font-size: 16px;
                                line-height: 16px;
                            }

                            @include media-query(350px) {
                                padding: 10px 0;
                            }
                        }
                    }

                    &:first-child {
                        border-radius: 8px 0 0 0;
                        a {
                            &:hover {
                                border-radius: 8px 0 0 0;
                            }
                        }
                    }

                    &:last-child {
                        border-radius: 0 8px 0 0;
                        a {
                            &:hover {
                                border-radius: 0 8px 0 0;
                            }
                        }
                    }
                }
            }

            .search-block-content {
                padding-top: 60px;

                .search-tooltip {
                    top: 80px;
                    border: 2px solid $pt-black;
                    border-radius: 30px;
                    @include media-query(930px) {
                        top: 55px;
                        right: 15px;
                    }

                    @include media-query(698px) {
                        top: 51px;
                    }

                    @include media-query(658px) {
                        top: 71px;
                    }

                    &:before {
                        color: $pt-black;
                        line-height: 18px;
                    }
                }

                .search-tooltip-content {
                    @include media-query(767px) {
                        left: 0;
                    }

                    @include media-query(601px) {
                        left: -290px;
                    }

                    @include media-query(401px) {
                        width: 270px;
                        left: -244px;
                    }

                    @include media-query(331px) {
                        width: 250px;
                        left: -225px;
                    }
                }

                h3 {
                    color: $brand-primary;
                    text-transform: capitalize;
                    @include media-query(930px) {
                        margin-top: 0;
                        font-size: 14px;
                    }
                    @media screen and (min-width: 768px) and (max-width: 930px) {
                        margin-top: 10px;
                    }

                    @include media-query(698px) {
                        margin-top: 0 !important;
                    }

                    @include media-query(678px) {
                        font-size: 12px;
                    }

                    @include media-query(638px, 659px) {
                        font-size: 10px;
                    }

                    @include media-query(658px) {
                        font-size: 18px;
                        margin-bottom: 10px;
                        padding-top: 25px;
                    }
                }

                form {
                    select {
                        background-image: url("../images/arrow-down-black.svg"); 
                        @include media-query(930px) {
                            margin-bottom: 10px;
                            padding: 6px 35px 6px 10px;
                        }

                        @include media-query(767px) {
                            padding: 5px 35px 5px 10px;
                            background-size: 16px 8px;
                        }

                        @include media-query(628px) {
                            margin-bottom: 7px;
                        }

                        @include media-query(618px) {
                            margin-bottom: 5px;
                        }

                        @include media-query(658px) {
                            margin-bottom: 10px;
                        }
                    }

                    input[type='text'] {
                        padding: 0 12px;

                        @include media-query(930px) {
                            height: 31px;
                            line-height: 31px;
                        }
                    }

                    button {
                        &[type='button'] {
                            font-family: 'effra';
                            font-weight: bold;
                            background: $brand-primary;
                            transition: 0.15s linear;
                            span {
                                text-align: center;
                                &:before {
                                    content: none;
                                    @include media-query(767px) {
                                        width: 20px;
                                        margin-top: -13px;
                                        font-size: 18px;
                                    }
                                }
                            }

                            @include media-query(767px) {
                                font-size: 16px;
                            }
                        }

                        @include media-query(800px) {
                            padding: 8px 12px !important;
                        }
                    }

                    @include media-query(930px) {
                        padding-top: 0;
                    }
                }

                @include media-query(930px, 659px) {
                    padding: 50px 20px 20px 20px;
                }

                @include media-query(658px) {
                    padding: 90px 20px 20px 20px;
                }

                @include media-query(350px) {
                    padding: 80px 20px 20px 20px;
                }
            }

            @include media-query(1100px, 1030px) {
                margin-left: -480px;
            }

            @include media-query(1030px, 991px) {
                margin-left: -460px;
            }

            @include media-query(990px, 931px) {
                margin-left: -450px;
            }

            @include media-query(930px) {
                margin-left: -410px;
                max-width: 300px;

                .search-block-content {
                    padding: 50px 15px 20px 15px;

                    form button[type='button'] {
                        font-size: 16px;
                        padding: 6px 12px !important;

                        span:before {
                            margin-top: -16px;
                            font-size: 22px;
                        }
                    }
                }
            }

            @include media-query(840px, 801px) {
                margin-left: -380px;
            }

            @include media-query(800px, 769px) {
                margin-left: -375px;
                max-width: 280px;
            }

            @include media-query(768px) {
                margin-left: -365px;
                max-width: 280px;
            }

            @include media-query(748px, 729px) {
                margin-left: -355px;
                max-width: 260px;
            }

            @include media-query(728px, 699px) {
                margin-left: -339px;
            }

            @include media-query(698px, 679px) {
                margin-left: -325px;
                max-width: 260px;
            }

            @include media-query(678px, 659px) {
                margin-left: -315px;
                max-width: 240px;
            }

            @include media-query(658px, 629px) {
                margin-left: -305px;
                max-width: 220px;
            }

            @include media-query(628px, 619px) {
                margin-left: -305px;
                max-width: 220px;
            }

            @include media-query(618px, 602px) {
                margin-left: -285px;
                max-width: 220px;
            }

            @include media-query(601px) {
                position: relative;
                top: 15px;
                left: 0;
                transform: translateY(0);
                max-width: 85%;
                margin: 0 auto;
                display: block !important;
            }
        }
    }

    @include media-query(658px) {
        @include blue-linear();
        height: 360px;
        min-height: 360px;
    }

    @include media-query(350px) {
        padding-bottom: 30px;
    }
}

@media (max-width: 1229px) {
    .main-slider.container {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
    }
}

.shopby-manufacturers {
    width: 100%;
    clear: both;
    display: block;
    background: $pt-gray-01;
    padding: 10px 0 90px 0;
    overflow-x: hidden;

    .slick-track {
        display: flex;

        .slick-slide {
            height: auto;

            >div,
            >div>div {
                height: 100%;
            }
        }
    }

    .slider-menu-wrapper {
        position: relative;
        opacity: 0;
        margin: 0 -5px;
        visibility: hidden;
        transition: opacity 1s ease;

        .slider-item {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 0 10px 20px 10px;
            box-sizing: border-box;
            background: white;
            border-radius: 15px;

            .brand-img,
            .brand-text {
                width: 100%;
                flex-basis: 100%;
            }

            .brand-img {
                display: flex;
                text-align: center;
                padding: 20px;
                min-height: 150px;
                align-self: center;

                a {
                    align-self: center;
                    width: 100%;
                }

                img {
                    max-width: 100%;
                    margin: 0 auto;
                }
            }

            .brand-text {
                border-top: 1px solid #f5f5f5;
                align-self: flex-end;
            }

            h3 {
                margin-top: 15px;
                margin-bottom: 20px;
                font-size: 20px;
                @include media-query(580px) {
                    font-size: 18px;
                }
            }

            a {
                color: #333333;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: $brand-primary;
                    text-decoration: none;
                }

                &:active {
                    color: $brand-primary;
                    text-decoration: none;
                }

                &:focus {
                    color: $brand-primary;
                    text-decoration: none;
                }

                &.button {
                    display: inline-block;
                    background: $brand-primary;
                    color: white;
                    font-family: inherit;
                    font-weight: 600;
                    padding-left: 8px;
                    padding-right: 8px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    border-radius: 5px;
                    border: none;
                    text-transform: uppercase;
                    font-size: 12px;
                    transition: all 0.15s ease-out;
                    font-family: 'effra';
                    &:hover {
                        background: $brand-hover!important;
                        color: white;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $brand-hover;
                    }

                    &:active {
                        color: white;
                        background: $brand-primary;
                        text-decoration: none;
                    }

                    &:focus {
                        background: $brand-hover!important;
                        color: white;
                        outline: 2px solid #fff;
                        box-shadow: 0 0 0 4px $brand-hover;
                    }
                }
            }
        }

        .slick-slide {
            padding: 0 5px !important;
        }

        &.slick-initialized {
            visibility: visible;
            opacity: 1;
        }
        .slick-arrow {
            background: none!important;
            &:hover {
                background: none!important;
            }
            &.slick-prev {
                left: -70px;
                transform: rotate(0deg);
                top: 30%;
                @include media-query(992px, 541px) {
                    left: calc(50% - 38px);
                    bottom: -90px;
                    top: auto;
                }
                @include media-query(767px) {
                    left: 10px;
                    top: 45%;
                }
                @media screen and (min-width: 993px) and (max-width: 1300px) {
                    left: 0;
                }
                &:before {
                    content: "\e96f";
                    top: 0;
                    color: $brand-primary;
                }
            }
    
            &.slick-next {
                right: -84px;
                @include media-query(992px, 541px) {
                    right: calc(50% - 38px);
                    bottom: -90px;
                    top: auto;
                }
                @include media-query(767px) {
                    right: 10px;
                }
                @media screen and (min-width: 993px) and (max-width: 1300px) {
                    right: 0;
                }
                &:before {
                    content: "\e970";
                }
            }
        }
    }

    &.manufacturer-all {
        .slick-list {
            @include media-query(1300px) {
                margin: 0 60px;
            }

            @include media-query(992px) {
                margin: 0;
            }
        }
    }

    &.manufacturer-exclusive {
        @include media-query(991px, 540px) {
            padding-bottom: 135px;
        }

        @include media-query(539px) {
            padding-bottom: 105px;
        }
    }

    &.manufacturer-all,
    &.manufacturer-exclusive {
        @include media-query(992px, 540px) {
            padding-bottom: 140px;
        }

        @include media-query(539px) {
            padding-bottom: 105px;
        }

        .slider-menu-wrapper {
            @include media-query(539px) {
                button {

                    &.slick-prev,
                    &.slick-next {
                        top: inherit;
                        bottom: -105px;
                        background: none!important;
                        &:before {
                            position: absolute;
                            font-family: 'icomoon';
                            width: 30px;
                            height: 30px;
                            font-size: 30px;
                            color: $brand-primary;
                            left: 8px;
                            top: 0;
                        }
                    }

                    &.slick-prev {
                        left: 0;
                        transform: rotate(0deg);
                        top: 40%;
                    }

                    &.slick-next {
                        right: 20px;
                        bottom: auto;
                        top: 47%;
                    }
                }
            }
        }
    }
}

.block-products-list {
    padding-left: 15px;
    padding-right: 15px;
}

.column.main {
    >#amasty-shopby-product-list {
        margin: 0 auto;

        @media (min-width: $screen-sm-min) {
            width: $container-sm;
        }

        @media (min-width: $screen-md-min) {
            width: $container-md;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-lg;
        }
    }
}

.fullwidth-popular-products {
    width: 100%;
    clear: both;
    display: block;

    #amasty-shopby-product-list {
        margin: 0 auto;

        @include media-query(620px) {
            padding: 20px 0;
        }
    }
}

.cms-index-index {
    .slick-dotted.slick-slider {
        margin-bottom: 0;
        padding-right: 0;
    }

    #maincontent {
        margin-top: 0;

        .messages {
            margin-top: 20px;
        }
    }

    .sections-headings {
        position: relative;
        border-bottom: 2px solid $pt-black;
        margin-top: 35px;
        margin-bottom: 25px;
        padding: 20px 0 10px 0;

        h2 {
            margin: 0;
            padding: 0;
            text-transform: capitalize;
            color: $pt-black;
            font-weight: bold;
            letter-spacing: -1px;
            font-size: 40px;
            padding-right: 80px;

            @include media-query(700px) {
                font-size: 26px;
            }

            @include media-query(620px) {
                font-size: 22px;
            }

            @include media-query(425px) {
                font-size: 18px;
            }

            @include media-query(332px) {
                font-size: 14px;
            }
        }

        a {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
            background: $brand-primary;
            @include transitions(400ms);
            text-decoration: none;
            color: #fff;
            width: 80px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            border-radius: 5px;
            font-family: 'effra';
            transition: all 0.15s ease-out;
            &:hover {
                background: $brand-hover!important;
                color: white;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            }

            &:active {
                background: $brand-primary;
            }

            &:focus {
                background: $brand-hover!important;
                color: white;
                outline: 2px solid #fff;
                box-shadow: 0 0 0 4px $brand-hover;
            }

            @include media-query(620px) {
                margin-top: -15px;
            }
        }

        @include media-query(710px) {
            margin-top: 0;
        }

        @include media-query(510px) {
            padding: 20px 120px 10px 0;
        }
    }

    .block-products-list {
        margin-right: auto;
        margin-left: auto;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 65px;

        @media (min-width: $screen-sm-min) {
            width: $container-sm + 30px;
            padding-left: 15px;
            padding-right: 15px;
        }

        @media (min-width: $screen-md-min) {
            width: $container-md + 30px;
            padding-left: 15px;
            padding-right: 15px;
        }

        @media (min-width: $screen-lg-min) {
            width: $container-lg + 30px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .block-title {
            display: none;
        }
        .product-item-details {
            .product-item-name {
                a {
                    font-size: 18px;
                }
            }
        }
        .product-item-attributes {
            .product-item-info {
                strong {
                    font-weight: 900;
                }
            }
        }
    }
}

.reasons-for-first-choice {
    background: $brand-secondary;
    padding: 0 0 50px;

    .inner-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    h2 {
        color: white;
        font-size: 40px;
        text-transform: capitalize;
        letter-spacing: -1px;
        border-bottom: 2px solid white;
        font-family: inherit;
        font-weight: bold;
        margin-bottom: 20px;
        font-family: 'effra';
        @include media-query(991px) {
            font-size: 30px;
        }

        @include media-query(620px) {
            font-size: 26px;
            letter-spacing: 0;
        }
    }

    .reasons-wrapper {
        margin-left: 15px !important;
        margin-right: 15px !important;
        position: relative;
        border: 2px solid white;
        border-radius: 6px;
        overflow: hidden;

        ul {
            background: $brand-primary;
            position: absolute;
            left: 0;
            top: 0;
            bottom: -10px;
            list-style-type: none;
            margin: 0;
            padding: 15px 0 15px 20px;
            max-width: 230px;

            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid #fff;

                &:last-child {
                    border-bottom: none;
                }

                >a {
                    text-align: left;
                    color: white;
                    font-size: 16px;
                    font-weight: bold;
                    width: 100%;
                    display: inline-block;
                    padding: 20px 10px 20px 60px;
                    line-height: 16px;
                    cursor: pointer;
                    position: relative;
                    font-family: 'effra';

                    &:hover {
                        box-shadow: none;
                        outline: 0;
                        text-decoration: none;
                    }

                    &:active {
                        box-shadow: none;
                        outline: 0;
                        text-decoration: none;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                        text-decoration: none;
                    }

                    .reasons-block-icon {
                        display: inline-block;
                        width: 42px;
                        height: 42px;
                        position: absolute;
                        left: 8px;
                        top: 50%;
                        margin-top: -21px;
                        cursor: pointer;
                        text-align: center;

                        img {
                            width: 100%;
                            max-width: 42px;
                            height: auto;
                        }
                    }

                    span {
                        display: inline-block;
                        background: $brand-secondary;
                        border-radius: 50%;
                        width: 42px;
                        height: 42px;
                        position: absolute;
                        left: 8px;
                        top: 50%;
                        margin-top: -21px;
                        cursor: pointer;

                        &.one_tab_item {
                            &:before {
                                content: "\e911";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -11px;
                                margin-top: -11px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 24px;
                                color: white;
                            }
                        }

                        &.two_tab_item {
                            &:before {
                                content: "\e914";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -14px;
                                margin-top: -10px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 20px;
                                color: white;
                            }
                        }

                        &.three_tab_item {
                            &:before {
                                content: "\e90d";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -11px;
                                margin-top: -11px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 24px;
                                color: white;
                            }
                        }

                        &.four_tab_item {
                            &:before {
                                content: "\e90f";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -13px;
                                margin-top: -11px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 21px;
                                color: white;
                            }
                        }

                        &.five_tab_item {
                            &:before {
                                content: "\e902";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -13px;
                                margin-top: -11px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 22px;
                                color: white;
                            }
                        }

                        &.six_tab_item {
                            &:before {
                                content: "\e908";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -8px;
                                margin-top: -13px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 27px;
                                color: white;
                            }
                        }

                        &.seven_tab_item {
                            &:before {
                                content: "\e90b";
                                position: absolute;
                                font-family: 'icomoon' !important;
                                left: 50%;
                                top: 50%;
                                margin-left: -14px;
                                margin-top: -11px;
                                font-style: normal;
                                font-weight: normal;
                                font-variant: normal;
                                text-transform: none;
                                speak: none;
                                line-height: 1;
                                font-size: 23px;
                                color: white;
                            }
                        }
                    }
                }

                &[aria-selected="true"] {
                    a {
                        background: white;
                        color: $pt-black;
                        text-decoration: none;
                        border-radius: 4px 0 0 4px;
                    }
                }

                &.mobile-view-li {
                    >a {
                        background: $brand-primary url('../images/arrow-down-white.svg') no-repeat calc(100% - 20px) center;
                        background-size: 20px 10px;
                        padding: 20px 50px 20px 60px;
                    }

                    &[aria-selected="true"] {
                        >a {
                            border-radius: 0;
                            padding: 20px 10px 20px 60px;
                            background-color: #fff;
                            background-image: url('../images/arrow-up-blue.svg');

                        }
                    }
                }
            }

            &.mobile-view {
                position: relative;
                max-width: 100%;
                padding: 10px 0 15px 0;
            }
        }

        div {
            &[data-role="content"] {
                background: white;
                float: left;
                display: block;
                width: 100%;
                padding: 20px 20px 20px 270px;
                box-sizing: border-box;
                overflow: auto;

                h1,
                h2,
                h3,
                h4,
                h5 {
                    color: $brand-primary;
                }
                h3 {
                    font-family: 'effra';
                    color: $pt-black;
                }
                p {
                    a {
                        color: $brand-primary;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &.mobile-view-contents {
                padding: 20px 20px 20px 20px;
                float: none;
                min-height: 0;
                overflow-y: auto;
            }
        }
    }

    @include media-query(420px) {
        padding: 10px 0;
    }
}

.promos-wrapper {
    padding-top: 40px;
    clear: both;
    width: 100%;
    display: block;

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
    }

    .promo-item {
        margin-bottom: 40px;

        @include media-query(991px, 658px) {
            width: 49.5%;
            float: left;
        }

        @include media-query(600px) {
            display: none;
            width: 100%;

            &:nth-child(-n+3) {
                display: block;
            }
        }

        a {
            img {
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover {
                img {
                    opacity: 0.75;
                }
            }
        }

        img {
            height: auto;

            @include media-query(600px) {
                width: 100%;
            }
        }
    }
}

.block-products-list,
.shopby-manufacturers {
    .slick-slider {
        @include media-query(992px, 540px) {
            margin-bottom: 70px;
        }

        .slick-list {
            @include media-query(1300px) {
                margin: 0 50px;
            }

            @include media-query(992px, 540px) {
                margin: 0;
            }
        }
    }
}
.product-items.slick-slider {
    .slick-arrow {
        background: none!important;
        &:hover {
            background: none!important;
        }
        &.slick-prev {
            left: -5%;
            transform: rotate(0deg);
            top: 40%;
            @include media-query(767px) {
                left: 10px;
            }
            @include media-query(992px, 541px) {
                left: calc(50% - 38px);
                bottom: -64px;
                top: auto;
            }
            @media screen and (min-width: 768px) and (max-width: 992px) {
                bottom: -38px;
            }
            @media screen and (min-width: 993px) and (max-width: 1300px) {
                left: 0;
            }
            &:before {
                content: "\e96f";
                top: 50px;
                color: $brand-primary;
                @include media-query(767px) {
                    top: 75%;
                }
                @media screen and (min-width: 993px) {
                    top: 30px;
                }
            }
        }

        &.slick-next {
            right: -5%;
            @include media-query(767px) {
                right: 10px;
                top: 45%;
            }
            @include media-query(992px, 541px) {
                right: calc(50% - 38px);
                bottom: -90px;
                top: auto;
            }
            @media screen and (min-width: 993px) and (max-width: 1300px) {
                right: 0;
            }
            &:before {
                content: "\e970";
            }
        }
    }
}




.feefo-section {
    background: $brand-secondary;
    width: 100%;
    clear: both;
    display: block;
    padding: 60px 0;

    >.container {
        position: relative;
    }

    .feefo-header {
        position: relative;
        border-bottom: 4px solid #fff;
        margin-bottom: 30px;
        padding-bottom: 15px;
        font-size: 0;

        .header-text,
        .feefo-snippet {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
        }

        .header-text {
            width: calc(100% - 250px);

            @include media-query(767px) {
                width: 100%;
                text-align: center;
            }
        }

        .feefo-snippet {
            width: 235px;
            margin-left: 15px;

            @include media-query(767px) {
                width: 100%;
                margin-left: 0;
                margin-top: 15px;
                text-align: center;
            }
        }

        @include media-query(767px) {
            padding-bottom: 35px;
        }

        @include media-query(600px) {
            p {
                font-size: 18px;
            }
        }
    }

    h2 {
        margin-top: 0;
        margin-bottom: 0;
        color: white;
        font-size: 40px;
        font-family: inherit;
        letter-spacing: -1px;
        text-transform: capitalize;
        font-weight: bold;
        font-family: 'effra';
        @include media-query(440px) {
            font-size: 34px;
        }
    }

    p {
        color: white;
        font-size: 21px;
        font-weight: 100;
    }

    .review-list {
        @include media-query(600px) {
            text-align: center;
        }

        .review {
            margin-bottom: 20px;

            @include media-query(767px) {
                margin-bottom: 40px;
            }

            @include media-query(600px) {
                &:not(:first-child) {
                    display: none;
                }
            }

            .review-title,
            blockquote p {
                font-style: italic;
            }

            .review-rating {
                height: 0;
                width: 0;
                background: url('../images/stars-sprite.png') repeat-x 0 -30px;
                background-size: 35px;

                @include media-query(600px) {
                    margin-right: auto;
                    margin-left: auto;
                }

                &[class*="rating-"] {
                    height: 30px;
                    margin-bottom: 15px;
                }

                &.rating-1 {
                    width: 35px;
                }

                &.rating-2 {
                    width: 70px;
                }

                &.rating-3 {
                    width: 105px;
                }

                &.rating-4 {
                    width: 140px;
                }

                &.rating-5 {
                    width: 175px;
                }
            }

            .review-title {
                margin-top: 0;
                margin-bottom: 15px;
                color: #fff;
                font-size: 28px;
                font-weight: 700;

                @include media-query(440px) {
                    font-size: 25px;
                }
            }

            blockquote {
                border: none;
                padding: 0;

                p {
                    margin-bottom: 15px;
                    font-size: 25px;
                    line-height: 30px;

                    @include media-query(440px) {
                        font-size: 21px;
                        line-height: 26px;
                    }
                }

                cite {
                    color: #fff;
                    font-weight: 700;

                    &:before {
                        content: '\2013\00A0';
                    }
                }
            }
        }
    }

    a {
        &.see-all-reviews {
            display: inline-block;
            background: white;
            width: 155px;
            height: 36px;
            @include transitions(400ms);
            text-decoration: none;
            color: $brand-primary;
            text-align: center;
            line-height: 38px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
            font-family: 'effra';
            transition: all 0.15s ease-out;
            &:hover,
            &:active,
            &:focus {
                background: $brand-primary;
                color: white;
                outline: 2px solid $brand-primary;
                box-shadow: 0 0 0 4px #fff;
            }
        }
    }

    .feefo-container {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .feefowidget-standard {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .promos-wrapper .col-md-4.promo-item {
        width: 33%;
    }
}
.product-price-label {
    margin: 0 0 5px;
}

//
//  Compatibility with Amasty Jet Theme
//  ______________________________________________

//
//  Common
//  ----------------------------------------------
$amgdprcookie-text__color: #363636;
$amgdprcookie-btn__background: #ef7c4a;
$amgdprcookie-btn__text: #fff;
$amgdprcookie-container__background: #fff;
$amgdprcookie-toogle-disable__background: #e8e8e8;
$amgdprcookie-scroll__color: #e0e0e0;
$amgdprcookie-scroll__background: $amgdprcookie-toogle-disable__background;
$amgdprcookie-toogle-normal__background: #a5a5a5;
$amgdprcookie-toogle__length: 25px;
$amgdprcookie-toogle__padding: 2px;
$amgdprcookie-toogle-checked__background: #1979c3;
$amgdprcookie-link__hover__color: #1787e0;
$amgdprcookie-link__color: #5384ec;
$amgdprcookie-link__base_color: #389dfe;
$amgdprcookie-button__focus: 0 0 3px 1px #00699d;
$amgdprcookie-btn-done__highlight: #1357e6;
$amgdprcookie-popup__shadow: #0a6c9f;
$amgdprcookie-btn-close-icon__background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4IiBzdHJva2U9IiNDQUNBQ0EiLz48cGF0aCBkPSJNNS4yNSA1LjI1bDYuNSA2LjVtMC02LjVsLTYuNSA2LjUiIHN0cm9rZT0iI0NBQ0FDQSIvPjwvc3ZnPg==');
$color-white: #fff;

.amgdprcookie-toggle-cookie .amgdprcookie-label {
    & {
        padding: 0;
    }

    &:before {
        content: none;
    }

    &:after {
        margin: 0;
    }
}

.amgdprcookie-text-container {
    font-size: 1.4rem;
}

.amgdprcookie-modal-container {
    .amgdprcookie-button {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }

    &.modal-popup .modal-inner-wrap {
        max-height: 100%;
    }
}

.amasty-jet-theme .amgdprjs-bar-template {
    z-index: 100;
}

.amgdprcookie-bar-container .amgdprcookie-button {
    border-radius: .4rem;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 2rem;
    margin-right: 1rem;
}

.modal-popup {
    overflow-y: inherit;
}

.modal-popup .modal-inner-wrap {
    width: auto;
}

.modal-popup.amgdprcookie-groups-modal.gdpr-cookie-container_setup-modal  {
    top: 0;
    transform: none;
}

.amgdprcookie-cookie-container {
    & {
        margin: 0 10px 20px 0;
        padding: 0 0 25px 0;
    }

    &.-modal:first-child {
        padding-top: 25px;
    }

    &.-modal .amgdprcookie-header,
    &.-modal .amgdprcookie-text {
        padding-left: 25px;
    }

    &.-modal {
        box-shadow: none;
        direction: ltr;
        margin-right: 30px;
    }

    .amgdprcookie-header {
        display: flex;
        justify-content: space-between;
    }

    .amgdprcookie-caption {
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        margin: 0 0 15px;
    }

    .amgdprcookie-text {
        color: $amgdprcookie-text__color;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .amgdprcookie-options {
        color: $amgdprcookie-link__color;
        cursor: pointer;
        font-size: 12px;
    }
}

.amgdprcookie-toggle-cookie {
    & {
        padding-left: 50px;
        position: relative;
    }

    .amgdprcookie-input {
        height: 0;
        opacity: 0;
        width: 0;
    }

    .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-normal__background;
            border-radius: 40px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, .05);
            cursor: pointer;
            height: 29px;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
        }

        &:after {
            background: $amgdprcookie-container__background;
            border-radius: 40px;
            content: '';
            height: $amgdprcookie-toogle__length;
            left: $amgdprcookie-toogle__padding;
            position: absolute;
            top: $amgdprcookie-toogle__padding;
            transition: all .4s ease;
            width: $amgdprcookie-toogle__length;
        }
    }

    .amgdprcookie-input:active + .amgdprcookie-label,
    .amgdprcookie-input:focus + .amgdprcookie-label {
        box-shadow: $amgdprcookie-button__focus;
    }

    .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-disable__background;
        }

        &:after {
            left: 95%;
            transform: translateX(-100%);
        }
    }

    .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-checked__background;
        }

        &:after {
            left: 95%;
            transform: translateX(-100%);
        }
    }

    .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
        background: $amgdprcookie-toogle-normal__background;
    }

    .amgdprcookie-input:disabled + .amgdprcookie-label {
        & {
            background: $amgdprcookie-toogle-disable__background;
        }

        &:after {
            left: $amgdprcookie-toogle__padding;
        }
    }

    .amgdprcookie-input:focus + .amgdprcookie-label {
        box-shadow: $amgdprcookie-button__focus;
    }
}

.amgdprcookie-main-wrapper {
    & {
        color: $amgdprcookie-text__color;
    }

    .amgdprcookie-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .amgdprcookie-head {
        font-size: 20px;
        line-height: 20px;
    }

    .amgdprcookie-btn-wrapper {
        display: flex;
    }

    .amgdprcookie-btn {
        & {
            background: $amgdprcookie-btn__background;
            border: 0;
            border-radius: 6px;
            box-shadow: 0 3px 5px rgba(62, 40, 13, .15);
            color: $amgdprcookie-btn__text;
            font-size: 14px;
            padding: 7px 27px;
            text-transform: uppercase;
        }

        &:first-child {
            margin: 0 15px;
        }

        &:hover {
            background: darken($amgdprcookie-btn__background, 10%);
        }

        &:focus,
        &:active {
            box-shadow: $amgdprcookie-button__focus;
        }
    }
}

.amgdprcookie-cookie-container .amgdprcookie-link {
    color: $amgdprcookie-link__base_color;
    cursor: pointer;
    float: right;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize;
}

.amgdprjs-bar-template {
    & {
        display: block !important;
        position: fixed; /* added to support older browsers */
        position: sticky;
    }

    .amgdprcookie-buttons-block .amgdprcookie-button {
        margin-right: 5px;
    }
}

.amgdprjs-bar-template {
    &.-popup {
        & {
            left: 50%;
            margin: 0 auto;
            max-width: 740px;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:before {
            background-color: $amgdprcookie-popup__shadow;
            content: '';
            height: 100%;
            width: 100%;
        }

        .amgdprcookie-bar-container {
            padding: 50px;
        }

        .amgdprcookie-bar-block {
            width: 100%;
        }
    }

    .amgdprcookie-buttons-block {
        display: flex;
        justify-content: center;
    }
}





//
//  Tablet
//  ----------------------------------------------

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .amgdprcookie-main-wrapper {
        & {
            padding: 0;
        }

        .amgdprcookie-top {
            margin-bottom: 35px;
        }

        .amgdprcookie-head {
            font-size: 34px;
            line-height: normal;
        }

        .amgdprcookie-btn {
            box-shadow: 0 3px 10px rgba(62, 40, 13, .15);
            font-size: 20px;
            padding: 17px 46px;
            text-transform: none;
        }
    }

    .amgdprcookie-cookie-container {
        & {
            margin-bottom: 30px;
            padding: 25px 0;
        }

        .amgdprcookie-caption {
            font-size: 20px;
            margin-bottom: 15px;
        }

        .amgdprcookie-toggle-cookie {
            padding-left: 0;
        }

        .amgdprcookie-text {
            font-size: 14px;
        }

        .amgdprcookie-options {
            background: inherit;
            display: inline-block;
            font-size: 14px;
        }
    }
}



.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        border-radius: 4px;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        max-width: 740px;
    }

    &.-table .modal-inner-wrap {
        max-width: 1290px;
        min-width: 700px;
        overflow-x: auto;
    }

    .modal-title {
        border-bottom: none;
        font-size: 20px;
        font-weight: bold;
    }

    .action-close:focus {
        box-shadow: $amgdprcookie-button__focus;
    }

    .amgdprcookie-form {
        padding: 0 10px;
    }

    .amgdprcookie-done {
        background-color: $amgdprcookie-link__color;
        border-radius: 3px;
        box-shadow: none;
        color: $color-white;
        font-weight: bold;
        letter-spacing: 1.35px;
        text-transform: uppercase;
        transition: background-color .3s ease-out;
        width: 100%;
    }

    .amgdprcookie-done:hover {
        background-color: $amgdprcookie-btn-done__highlight;
    }

    .amgdprcookie-done:focus {
        background-color: $amgdprcookie-btn-done__highlight;
        box-shadow: $amgdprcookie-button__focus;
    }

    &:not(.-table) .modal-footer {
        padding-top: 0;
    }
}

.amgdprcookie-groups-modal .amgdprcookie-form-container,
.amgdprcookie-groups-modal .amgdprcookie-tbody {
    & {
        display: block;
        max-height: 500px;
        overflow-y: auto;
    }
}

.amgdprcookie-bar-container {
    & {
        background: $color-white;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 30px 10px;
        z-index: 10;
    }

    &.modal-popup {
        bottom: unset;
        left: unset;
        overflow-y: unset;
        pointer-events: auto;
        position: unset;
        right: unset;
        top: unset;
        transition: unset;
    }

    .amgdprcookie-buttons-block {
        display: flex;
        flex-direction: column;
    }

    .amgdprcookie-policy {
        margin-bottom: 20px;
        max-height: 30vh;
        max-width: 640px;
        overflow-y: auto;
    }

    .amgdprcookie-button:first-child {
        margin-bottom: 10px;
    }

    .amgdprcookie-buttons-block .amgdprcookie-button {
        margin-bottom: 0;
        padding: 10px 30px;
    }

    .action-close {
        & {
            line-height: 14px;
            right: 10px;
            top: 10px;
        }

        &:focus,
        &:active {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }
    }
}

.amgdprcookie-buttons-block {
    & {
        display: flex;
        flex-direction: column;
    }

    &.-settings {
        padding-bottom: 30px;
    }

    .amgdprcookie-button {
        & {
            background: $color-white;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            color: $amgdprcookie-link__color;
            font-weight: bold;
            letter-spacing: 1.35px;
            margin-top: 15px;
            padding: 10px 15px;
            text-transform: uppercase;
            transition: opacity .3s ease, color .3s ease-out, background-color .3s ease-out, border .3s ease-out;
        }

        &:hover {
            opacity: .9;
        }

        &:active,
        &:focus {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &.-allow,
        &.-save {
            background: $amgdprcookie-link__color;
            color: $color-white;
            flex-basis: 33%;
        }
    }
}

.amgdprcookie-modal-template {
    & {
        background: $color-white;
        margin-left: 5px;
        overflow-y: auto;
    }

    .action-close {
        & {
            background: $color-white;
            line-height: 14px;
            margin: 0;
            padding: 8px;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &:focus,
        &:active {
            background: $color-white;
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &:hover {
            background: $color-white;
        }
    }

    .amgdprcookie-button.-allow:not(.-save) {
        background: inherit;
        color: $amgdprcookie-link__color;
        margin-bottom: 0;
    }
}

.amgdprcookie-modal-container .modal-content {
    padding: 0;
}

.amgdprcookie-groups-container {
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    direction: rtl;
    height: auto;
    overflow-y: scroll;
}

.amgdprcookie-policy-container {
    bottom: 0;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    padding: 24px;
    position: absolute;
}

.amgdprcookie-text-container .amgdprcookie-policy {
    display: inline;
}

.amgdprcookie-modal-container.modal-popup.modal-slide {
    left: 0;
    pointer-events: none;
}

.amgdprcookie-modal-container .modal-inner-wrap {
    & {
        height: 100%;
        margin: 0;
        position: relative;
    }

    .modal-header {
        position: absolute;
        right: 0;
    }

    .action-close {
        & {
            margin: 5px;
            padding: 0;
        }

        &:before {
            background: $amgdprcookie-btn-close-icon__background-image no-repeat center;
            content: '';
            height: 25px;
            width: 25px;
        }

        &:focus {
            box-shadow: $amgdprcookie-button__focus;
        }
    }
}

.amgdprcookie-settings-form .amgdprcookie-toggle-cookie .amgdprcookie-input.disabled + .amgdprcookie-label {
    background: $amgdprcookie-toogle-disable__background;
}

.amgdprcookie-groups-modal {
    .amgdprcookie-table .amgdprcookie-row,
    .amgdprcookie-table thead tr,
    .amgdprcookie-table thead {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .amgdprcookie-cell.-name {
        word-wrap: break-word;
    }
}

.amgdprcookie-modal-container .amgdprcookie-modal-template {
    height: 100%;
    margin-left: 0;
    min-width: 400px;
    pointer-events: auto;
    position: relative;
    width: 20%;
    z-index: 900;
}

.ammodals-overlay {
    background-color: rgba(51, 51, 51, .55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 899;
}

@media (max-width: 767px) {
    .amgdprcookie-groups-modal {
        &.-table .modal-inner-wrap {
            min-width: 95%;
        }

        .amgdprcookie-table {
            font-size: 1rem;
        }
    }

    .modal-popup .modal-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .amgdprcookie-bar-container {
        .action-close {
            padding: 10px;
            right: 0;
            top: 0;
        }
    }

    .amgdprcookie-modal-container .amgdprcookie-modal-template {
        min-width: unset;
        width: unset;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .amgdprcookie-groups-modal {
        .amgdprcookie-cookie-container,
        .amgdprcookie-header,
        .amgdprcookie-text {
            padding: 0;
        }
    }

    .amgdprcookie-modal-container .modal-inner-wrap {
        background: $color-white;
        min-width: 400px;
        right: unset;
        width: 21%;
    }

    .amgdprcookie-table .amgdprcookie-cell.desc,
    .amgdprcookie-table .amgdprcookie-title.-wide {
        width: 30%;
    }

    .amgdprcookie-table .amgdprcookie-cell,
    .amgdprcookie-table .amgdprcookie-title {
        width: 15%;
    }

    .amgdprcookie-bar-container .amgdprcookie-buttons-block {
        & {
            flex-direction: row;
            margin-bottom: 5px;
        }

        .amgdprcookie-button {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1024px) {
    .amgdprcookie-groups-modal .amgdprcookie-form {
        padding: 0 20px;
    }

    .checkout-index-index {
        .modal-popup.amgdprcookie-modal-container {
            .modal-inner-wrap {
                left: unset;
                margin-left: unset;
                width: 21%;
            }
        }
    }
}
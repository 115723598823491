.navbar-brand, .top-search-wrapper, .top-links-wrapper {
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 768px) {
  .top-search-wrapper {
    display: inline-block !important;
  }
}

@media (max-width: 991px) {
  .header.container, .main-menu-container .container, .call-action-block-container {
    width: 100%;
  }
  .header.container {
    padding-right: 0;
  }
  .main-menu-container {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (max-width: 767px) {
  .header.container {
    padding-left: 0;
  }
}

.page-header {
  .top-search-wrapper {
    width: 100%;
    @include media-query(900px) {
      width: calc(100% - 504px);
      padding-left: 15px;
    }
    @include media-query(767px) {
      display: none;
      background: #e5e5e5;
      margin-right: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
      margin-top: 10px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1224px) {
      width: calc(100% - 627px);
      padding: 0 15px 0 5px;
    }
    @media (min-width: 1024px) {
      padding: 0 35px 0 35px;
      flex: 4;
    }
  }
}

.top-search-wrapper .amsearch-form-container {
  .amsearch-wrapper-content {
    .amsearch-autocomplete-information {
      width: calc(100% - 76px);
    }
  }
  .amsearch-item-container {
    &:after {
      background-color: #ededed;
    }
    > .amasty-xsearch-block-header {
      padding: 0;
      &:before {
        content: none;
      }
    }
  }
  #amasty-shopby-product-list {
    .price-box {
      width: 100%;
      max-width: 100%;
      margin: 10px 0 0;
      span.price {
        &:after {
          text-transform: none;
          font-size: 13px;
        }
      }
      .price-excluding-tax span.price {
        font-size: 21px;
        line-height: 22px;
      }
      .price-including-tax span.price {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .search-autocomplete {
    max-height: 85vh;
    @media screen and (min-width: 1024px) and (max-width: 1224px) {
      left: auto;
    }
    .amsearch-results {
      background-color: #fff;
      border: 1px solid #ededed;
      .amsearch-leftside {
        background-color: #fdfdfd;
        border-right: 1px solid #ededed;
        .amsearch-products {
          padding-bottom: 1px;
        }
      }
      .amsearch-more-results {
        margin: 15px 0;
        padding: 0 15px;
        .amsearch-link {
          display: inline-block;
          background-color: $brand-primary;
          border-radius: 5px;
          border: none;
          text-transform: uppercase;
          font-weight: 600;
          font-family: 'effra';
          transition: all 0.15s ease-out;
          &:hover {
            background: $brand-hover!important;
            color: white;
            outline: 2px solid #fff;
            box-shadow: 0 0 0 4px $brand-hover;
          }

          &:active {
              color: white;
              background: $brand-primary;
              text-decoration: none;
          }

          &:focus {
              background: $brand-hover!important;
              color: white;
              outline: 2px solid #fff;
              box-shadow: 0 0 0 4px $brand-hover;
          }
        }
      }
    }



    .amsearch-item.product-item {
      &:hover {
        background-color: #f1f1f1;
        box-shadow: none;
        cursor: pointer;
        * {
          cursor: pointer;
        }
      }
    }
    .amsearch-autocomplete-image {
      margin-bottom: 10px;
      img {
        max-width: 52px;
      }
    }
    .product-item-attributes {
      .product-item-info {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 6px;
        color: $pt-gray-09;
        strong {
          display: block;
          color: $pt-black;
        }
      }
    }
    .amsearch-clone-position {
      border-bottom: 1px solid #ededed;
      z-index: 9999999;
    }
  }
}
.amsearch-wrapper-input.amsearch-left-position, .search-autocomplete.amsearch-left-position, .amsearch-clone-position {
  left: 0;
  right: unset;
}
.amsearch-clone-position {
  @include media-query(768px) {
    margin-top: 40px;
  }
}

@include media-query(767px) {
  .header.container {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    .top-links-wrapper {
      order: 1;
      width: calc(100% - 214px);
    }
    .top-search-wrapper {
      order: 2;
      width: 100%;
      height: 60px;
      padding: 10px;
      .top-link-block {
        display: block;
        float: right;
        right: 0;
        &:before {
          top: 0;
        }
      }
    }
    #search_mini_form {
      .top-link-block {
        position: absolute;
        top: -2px;
      }
      input[type="text"] {
        width: 100%;
      }
    }
  }
}

@include media-query(767px) {
  .header.container {
    .top-links-wrapper {
      width: calc(100% - 339px);
    }
  }
  .main-menu-container {
    display: none !important;
  }
}

@include media-query(710px) {
  .header.container {
    .top-links-wrapper {
      width: 100%;
      border-top: 1px solid $pt-gray-04 !important;
    }
  }
}
@media (max-width: 767px) {
  .header.container {
    .top-links-wrapper {
      width: 100%;
      border-top: 1px solid $pt-gray-04 !important;
    }
  }
}

.wishlist-index-index {
    .product-item-info {
        .product-item-attributes {
            border-bottom: none !important;
        }
    }
}

.page-header {
  .top-search-wrapper {
    width: 100%;
    .amsearch-wrapper-block {
      position: relative;
      .amsearch-form-block {
        &.-opened {
          width: 100%!important;
        }
        .amsearch-input-wrapper {
          display: flex;
          margin-top: 16px;
          input {
            width: 100%;
            height: 48px;
            flex: 2;
            float: left;
            line-height: 40px;
            background: #ffffff;
            text-shadow: none;
            color: #2D2926;
            font-family: 'effra';
            font-size: 16px;
              padding: 6px 12px;
          }
            input::placeholder {
                font-size: 16px;
            }
          .amsearch-button.-close {
            display: none;
          }
          .amsearch-button.-loupe {
            width: 100px;
            height: 48px;
            background: #C8102E;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: #C8102E;
            color: #fff;
            font-family: 'effra';
            text-transform: uppercase;
            font-size: 15px;
            font-weight: bold;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            transition: all 0.15s ease-out;
            cursor: pointer;
            &:before {
              content: 'Search';
              color: #fff;
              text-transform: uppercase;;
            }
            &:hover {
              background: #A6192E !important;
              color: white;
              outline: 2px solid #fff;
              box-shadow: 0 0 0 4px #A6192E;
            }
          }
        }
        .amsearch-result-section {
          position: absolute;
          z-index: 999;
          background: #fff;
          max-width: 600px;
          width: 100%;
          top: 37px;
          .amsearch-items-section {
            display: flex;
            overflow: auto;
            border: 1px solid #ededed;
            > div {
              border-right: 1px solid #ededed;
              flex: 1;
                .amsearch-item-container {
                  .amsearch-block-header {
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                    color: #000;
                  }
                  .amsearch-tab-items {
                    list-style: none;
                    padding: 0;
                    .amsearch-item {
                      .item-name {
                        color: #000;
                      }
                    }
                  }

                }
              &.amsearch-products-section {
                flex: 2;
                .amsearch-wrapper-content {
                  .amsearch-item {
                    &:hover {
                      background-color: #f1f1f1;
                      box-shadow: none;
                      cursor: pointer;
                    }
                    .amsearch-autocomplete-image {
                      .product-image-photo {
                        max-width: 52px;
                        height: auto;
                      }
                    }
                    .amsearch-autocomplete-information {
                      .information-container {
                        .product-item-details {
                          .product-item-link {
                            text-transform: capitalize;
                          }
                          .product-item-attributes {
                            .product-item-info {
                              font-size: 12px;
                              line-height: 14px;
                              margin-bottom: 6px;
                              color: #53565A;
                              strong {
                                display: block;
                                color: #2D2926;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .amsearch-more-results {
                  .amsearch-link {
                    display: inline-block;
                    background-color: #C8102E;
                    border-radius: 5px;
                    border: none;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: 'effra';
                    transition: all 0.15s ease-out;
                    &:hover, &:focus {
                      background: #A6192E !important;
                      color: white;
                      outline: 2px solid #fff;
                      box-shadow: 0 0 0 4px #A6192E;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

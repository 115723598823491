.minicart-wrapper {
  a.showcart {
    transition: all 0.3s ease;
    background: $brand-primary;
    padding:  50px 10px 10px;
    height: 80px;
    @media (max-width: 767px) {
      height: 70px;
      width: 70px;
    }
    @include media-query(900px) {
      padding: 43px 5px 11px;
    }
      .mini-cart-icon {
        color: white;
        font-family: inherit;
        text-transform: uppercase;
        font-size: 10px;
        padding: 0 6px;
        font-weight: 400;
        letter-spacing: -0.5px;
        @include media-query(390px) {
          padding: 0 5px;
        }
      }
      position: relative;
      &:before {
        position: absolute;
        font-family: 'icomoon';
        width: 20px;
        height: 20px;
        top: 15px;
        left: 50%;
        margin-left: -13px;
        font-size: 30px;
        content: "\e917";
        color: white;
        @include media-query(900px) {
          top: 13px;
          font-size: 24px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          top: 17px;
        }
      }
      cursor: pointer;
    .counter-label {
      display: none !important;
    }
    span.counter.qty {
      position: absolute;
      left: 50%;
      top: 54%;
      color: white;
      font-size: 12px;
      width: 20px;
      height: 20px;
      text-align: center;
      margin-left: -9px;
      margin-top: -12px;
      @include media-query(900px) {
        margin-left: -12px;
      }
    }
  }
}
.additional-header-links {
    flex: 1;
    @include media-query(900px) {
    }
    @include media-query(768px, 711px) {
    }
    @include media-query(767px) {
    }
    @include media-query(710px) {
      padding-right: 0;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      flex: unset;
      width: 40%;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      &.header-top-links {
        width: 100%;
        font-size: 0;
        display: flex;
        justify-content: flex-end;
        @include media-query(768px) {
          text-align: right;
        }
        @include media-query(768px, 711px) {
          padding-left: 10px;
        }
        @include media-query(710px) {
          text-align: center;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          justify-content: space-around;
        }
        li {
          position: relative;
          display: inline-block;
          margin-left: 0;
          @include media-query(768px, 711px) {
            width: 20%;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-left: 15px;
            width: auto;
          }
          a {
            padding: 10px 10px 10px 5px;
            text-align: center;
            display: flex;
            align-items: center;
            color: $gray-base;
            font-family: inherit;
            text-transform: capitalize;
            font-size: 13px;
            margin-right: 0;
            letter-spacing: -0.5px;
            font-weight: 400;
            text-decoration: none;
            height: 40px;
            cursor: pointer;
            transition: all 0.3s ease;
            @include media-query(900px) {
              width: 100%;
            }
            @include media-query(768px, 711px) {
              width: 100%;
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {
              display: inline-block;
            }
            &:before {
              position: unset;
              font-family: 'icomoon';
              width: 20px;
              height: 20px;
              top: 8px;
              left: 50%;
              margin-right: 5px;
              font-size: 13px;
              color: $brand-primary;
              @include media-query(900px) {
                font-size: 24px;
                top: 13px;
              }
              @media screen and (min-width: 768px) and (max-width: 1023px) {
                font-size: 14px;
              }
            }
            &:hover {
              text-decoration: none;
            }
            &:active {
              text-decoration: none;
            }
            &:focus {
              text-decoration: none;
            }
            &:hover {
                background: $pt-gray-04;
              &:before {
                color: $brand-primary;
              }
            }
            @include media-query(332px) {
              width: 50px;
              font-size: 8px;
              font-weight: 800;
            }
          }
          &.account {
             a {
               &:before {
                 margin-left: 0;
                 content: "\e915";
                 @include media-query(900px) {
                   margin-left: -13px;
                 }
               }
               &:hover {
                  background: $pt-gray-04;
               }
             }
          }
          &.quick-order {
            a {
              &:before {
                content: "\e913";
              }
            }
          }
          &.track-order {
            a {
              &:before {
                margin-left: 0;
                content: "\e914";
                @include media-query(900px) {
                  margin-left: -20px;
                }
              }
            }
          }
          &.wishlist {
            a {
              &:before {
                margin-left: 0;
                content: "\e916";
                @include media-query(900px) {
                  margin-left: -17px;
                }
              }
            }
          }
          @include media-query(710px) {
            display: table-cell;
          }
        }
      }
      @include media-query(710px) {
        text-align: center;
        display: table;
      }
    }
}
#search_mini_form {
  .top-link-block {
    width: 100px;
    text-align: center;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    &:hover {
      color: $brand-primary;
      &:before {
        color: $brand-primary;
      }
    }
  }
}
.search-reveal {
  display: none !important;
  width: 60px;
  height: 72px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  color: #000;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 10px;
  margin-right: 0;
  font-weight: 400;
  text-decoration: none;
  @media (max-width: 767px) {
    height: auto;
  }
  &:before {
    position: absolute;
    font-family: 'icomoon';
    content: "\e912";
    width: 20px;
    height: 20px;
    top: 5px;
    left: 50%;
    margin-left: -15px;
    font-size: 30px;
    color: #000;
    @media (max-width: 767px) {
      top: 6px;
      font-size: 24px;
    }
  }
  span {
    padding-top: 48px;
    padding-bottom: 10px;
    display: inline-block;
    @media (max-width: 767px) {
      padding-top: 39px;
    }
  }
  @include media-query(768px) {
    display: inline-block !important;
  }
  @include media-query(710px) {
    border-left: none !important;
  }
  @include media-query(332px) {
    width: 50px;
    font-size: 8px;
    font-weight: 800;
  }
}
